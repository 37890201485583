/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            author
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const image = site.siteMetadata.image;
  const siteUrl = site.siteMetadata.siteUrl;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: 'Drogevate Solutions pvt ltd.',
      alternateName: 'Drogevate - Noesis Platform',
      url: siteUrl,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: 'Drogevate Solutions pvt ltd.',
      url: siteUrl,
      sameAs: [
        'https://www.facebook.com/activatingdatafromdocuments/',
        'https://www.linkedin.com/company/drogevate/',
        'https://twitter.com/drogevate',
      ],
      logo: 'https://www.drogevate.com/logo.jpg',
      address: 'L-25, Sarita Vihar (403.25 km) 110076 Delhi, India',
      email: 'sales@drogevate.com',
    },
    {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: 'home',
          },
        },
      ],
    },
  ];

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <link rel="canonical" href={siteUrl} />
      {/* General tags */}
      <meta name="description" content={metaDescription} />
      <meta name="image" content={image} />
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      {/* OpenGraph tags */}
      <meta property="og:url" content="https://www.drogevate.com" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="drogevate" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;

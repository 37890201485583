import React from 'react';

import './reboot.css';
import './global.scss';
import styles from './Layout.module.scss';

const Layout = ({ children }) => {
  return (
    <>
      <div className={styles.Layout}>
        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
